import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Column = makeShortcode("Column");
const Tout = makeShortcode("Tout");
const Icon = makeShortcode("Icon");
const List = makeShortcode("List");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Blockchain`}</h1>
    <InfoBlock mdxType="InfoBlock">
      <p><img parentName="p" {...{
          "src": "/images/illustrations/Short-1.png",
          "alt": "Blockchain"
        }}></img></p>
      <Box mdxType="Box">
        <h2>{`Learn about Distributed Ledger Technology`}</h2>
      </Box>
    </InfoBlock>
    <p>{`Blockchain is a form of distributed ledger technology (DLT).`}</p>
    <p>{`By distributing data across a network of computers, DLT allows any group of individuals to maintain a public, auditable ledger of cryptographically-secured transactions.`}</p>
    <p>{`The result is a system capable of transforming global financial and organizational structures to better serve the public good.`}</p>
    <p>{`Blockchain technology provides an unprecedented opportunity to fix some of the systemic issues stemming from centralized power in financial systems and on the Internet.`}</p>
    <h2>{`Types of Blockchains`}</h2>
    <p>{`Distributed ledgers can be categorized as either public or private and permissioned or permissionless.`}</p>
    <Column mdxType="Column">
      <Box mdxType="Box">
        <h3>{`Public vs Private`}</h3>
        <p><strong parentName="p">{`Public`}</strong>{` means anyone can view the transactions on a distributed ledger.`}</p>
        <p><strong parentName="p">{`Private`}</strong>{` means that permission is required to participate in the network.`}</p>
      </Box>
      <Box mdxType="Box">
        <h4>{`Permissioned vs Permissionless`}</h4>
        <p><strong parentName="p">{`Permissionless`}</strong>{` means anyone can run a node and help secure a distributed ledger.`}</p>
        <p><strong parentName="p">{`Permissioned`}</strong>{` means permission must be given in order to run a node and help secure a distributed ledger.`}</p>
      </Box>
    </Column>
    <h2>{`Popular Blockchains`}</h2>
    <p>{`Bitcoin and Ethereum are the most popular examples of blockchain implementations.`}</p>
    <Tout mdxType="Tout">
      <Box mdxType="Box">
        <h2>{`Bitcoin `}<Icon name="btc_circle_color" size={30} mdxType="Icon" /></h2>
        <p>{`Learn about the origin of Bitcoin and cryptocurrency.`}</p>
        <p><a parentName="p" {...{
            "href": "https://github.com/wbnns/bitcoinwhitepaper/blob/master/English/bitcoin.pdf"
          }}>{`Bitcoin Whitepaper`}</a></p>
      </Box>
      <Box mdxType="Box">
        <h2>{`Ethereum `}<Icon name="ether_color" size={30} mdxType="Icon" /></h2>
        <p>{`Learn about Ethereum, smart contracts, and decentralized applications.`}</p>
        <p><a parentName="p" {...{
            "href": "https://ethereum.org/en/whitepaper/"
          }}>{`Ethereum Whitepaper`}</a></p>
      </Box>
    </Tout>
    <h2>{`Sections`}</h2>
    <List mdxType="List">
      <p><a parentName="p" {...{
          "href": "/learn/blockchain-fundamentals/ethereum/"
        }}>{`Ethereum`}</a></p>
      <p><a parentName="p" {...{
          "href": "/learn/blockchain-fundamentals/daos/"
        }}>{`What is a DAO?`}</a></p>
      <p><a parentName="p" {...{
          "href": "/learn/blockchain-fundamentals/wallets/"
        }}>{`Digital Wallets`}</a></p>
      <p><a parentName="p" {...{
          "href": "/learn/blockchain-fundamentals/stablecoins/"
        }}>{`Stablecoins`}</a></p>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      